.talk__container {
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.875rem;
}

.talk__card {
  box-shadow: var(--shadow);
  border-radius: var(--border-radius);
  overflow: hidden;
  border: 2px solid var(--border-color);
  background-color: var(--container-color);
}

.talk__thumb {
  position: relative;
  overflow: hidden;
}

.iframe-wrapper {
  aspect-ratio: 16/9;
}

iframe {
  width: 100%;
  height: 100%;
  margin: 0;
}

.talk__img {
  width: 100%;
  height: 290px;
  margin-bottom: 2px;
}

.talk__category {
  color: #fff;
  background-color: var(--primary-color);
  border-bottom-left-radius: 0.9375rem;
  border-bottom-right-radius: 0.9375rem;
  position: absolute;
  top: 0;
  left: 1.25rem;
  font-size: var(--small-font-size);
  display: inline-block;
  padding: 0.125rem 0.5rem;
  transition: 0.3s;
  z-index: 1;
}

.talk__details {
  background-color: var(--container-color);
  padding: 0.5rem;
}

.talk__title {
  font-size: var(--h3-font-size);
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  color: var(--secondary-color);
  text-decoration: underline;
  text-decoration-thickness: var(--underline-offset);
}

.talk-descp,
.talk-links {
  line-height: 1.4em;
  font-size: 16px;
  font-size: clamp(15px, 2vw, 17px);
}

.talk-links {
  margin-bottom: 1em;
}

.talk-descp {
  margin-left: 17px;
}

@media screen and (max-width: 1024px) {
  .talk__container {
    grid-template-columns: repeat(1, 330px);
    justify-content: center;
    gap: 1.875rem;
  }
}

@media screen and (max-width: 768px) {
  .talk__container {
    grid-template-columns: 310px;
  }

  .talk-descp {
    display: none;
  }

  .talk-links-item {
    padding: 0.5rem 0.75rem;
  }
}

@media screen and (max-width: 350px) {
  .talk__container {
    grid-template-columns: 1fr;
  }
}
