.hero {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

#hero-col-wrapper {
  width: 80vw;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h1 {
  font-size: 80px;
  margin-bottom: 0.2em;
  color: white;
}

h2 {
  font-size: 30px;
  font-weight: normal;
  color: white;
}

.socials {
  display: flex;
  column-gap: 1.75rem;
  margin-top: 1rem;
  margin-bottom: 0;
}

.socials__item {
  color: var(--border-color);
  font-size: 1.75rem;
  transition: 0.3s;
}

.socials__item:hover {
  color: var(--primary-color);
}

.icon {
  width: 15rem;
  width: clamp(200px, 30vw, 350px);
  margin: 1rem 0;
}

.scroll_down {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 4rem;
  left: 0;
  width: 100%;
}

.scroll {
  position: absolute;
  width: 28px;
  height: 8px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3s ease-out infinite;
}

.scroll:first-child {
  animation: move 3s ease-out 1s infinite;
}

.scroll:nth-child(2) {
  animation: move 3s ease-out 2s infinite;
}

.scroll:before,
.scroll:after {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: var(--primary-color);
}

.scroll:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.scroll:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}

@keyframes move {
  25% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    transform: translateY(30px);
  }
  67% {
    opacity: 1;
    transform: translateY(40px);
  }
  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}

@keyframes pulse {
  to {
    opacity: 1;
  }
}

.contact-btn {
  padding: 0.9rem 1rem;
  line-height: 1;
  border-radius: 7px;
  border: 1px solid transparent;
  display: block;
  color: #fff;
  text-align: center;
  font-size: 15px;
  background-color: var(--secondary-color);
  font-weight: var(--font-bold);
  transition: 0.3s;
}

.contact-btn:hover {
  background-color: var(--primary-color);
  transform: translate(0, -5%);
  transition: all 0.1s ease-in;
}

@media screen and (max-width: 920px) {
  #hero-col-wrapper {
    flex-direction: column-reverse;
    align-items: center;
  }

  h1 {
    font-size: 40px;
    text-align: center;
  }

  h2 {
    font-size: 20px;
    text-align: center;
  }

  .socials {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contact-btn {
    padding: 0.75rem 0.5rem;
    margin: auto;
    border-radius: 5px;
  }

  .socials__item {
    color: var(--border-color);
    font-size: 1.5rem;
    transition: 0.3s;
  }

  #main-col-2 {
    margin-bottom: 1em;
  }
}
