aside {
  position: fixed;
  right: 0;
  top: 0;
  background-color: var(--body-color);
  border-left: 2px solid var(--primary-color);
  padding: 2.5rem;
  width: 110px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 11;
  transition: 0.3s;
}

.nav__list {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.nav__link {
  font-size: 1.75rem;
  color: var(--border-color);
  font-weight: var(--font-bold);
  transition: 0.3s;
}

.nav__link:hover {
  color: var(--primary-color);
}

.footer__button {
  background-color: transparent;
}

.copyright {
  color: var(--title-color);
  font-size: var(--small-font-size);
  transform: rotate(-180deg);
  writing-mode: vertical-rl;
}

.nav__toggle {
  position: fixed;
  top: 1.25rem;
  right: 1.875rem;
  cursor: pointer;
  height: 40px;
  width: 40px;
  background-color: transparent;
  border: 2px solid var(--primary-color);
  color: var(--border-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: 0.3s;
  font-size: 1rem;
}

.nav__label {
  display: flex;
  color: white;
  margin-top: -10px;
  font-weight: normal;
  text-align: center;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .aside {
    right: -110px;
  }

  .nav__toggle {
    display: flex;
  }

  .nav__toggle-open {
    right: 140px;
  }

  .show-menu {
    right: 0;
  }
}

@media screen and (max-height: 680px) {
  .nav__label {
    display: none;
  }
}
