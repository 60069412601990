.project__container {
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1.875rem;
}

.project__card {
  box-shadow: var(--shadow);
  border-radius: var(--border-radius);
  border: 2px solid var(--border-color);
  overflow: hidden;
  background-color: var(--container-color);
}

.project__thumb {
  position: relative;
  overflow: hidden;
}

.project__img {
  width: 100%;
  height: 320px;
  cursor: pointer;
}

.project__thumb:hover .project__img {
  transform: scale(1.1);
}

.project__category {
  color: #fff;
  background-color: var(--primary-color);
  border-bottom-left-radius: 0.9375rem;
  border-bottom-right-radius: 0.9375rem;
  position: absolute;
  top: 0;
  left: 1.25rem;
  font-size: var(--small-font-size);
  display: inline-block;
  padding: 0.125rem 0.5rem;
  transition: 0.3s;
  z-index: 1;
}

.project__details {
  padding: 1.25rem;
}

.project__title {
  font-size: var(--h3-font-size);
  color: var(--primary-color);
  margin-bottom: 0.5rem;
  text-decoration: underline;
  text-decoration-thickness: var(--underline-offset);
}

.project__meta {
  display: flex;
  column-gap: 0.6rem;
  font-size: var(--small-font-size);
  color: #8b88b1;
}

@media screen and (max-width: 1024px) {
  .project__container {
    grid-template-columns: repeat(2, 330px);
    justify-content: center;
    gap: 1.875rem;
  }
}

@media screen and (max-width: 768px) {
  .project__container {
    grid-template-columns: 310px;
  }
}

@media screen and (max-width: 350px) {
  .project__container {
    grid-template-columns: 1fr;
  }
}
