.footer {
  padding: 1.5rem 0;
}

.footer__text {
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.footer__btn {
  float: right;
  border-radius: 50%;
  margin-top: -3rem;
  color: #ffffff;
}

@media screen and (max-width: 1024px) {
  .footer__btn {
    display: none;
  }
}
